<template>
    <modal ref="modalCrearEquipo" :titulo="`Crear equipo de ${$config.vendedor}`" tamano="modal-lg" :cargado-modal="cargando" no-aceptar adicional="Guardar" @adicional="crearTeam">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 justify-center">
                <div class="col-10 mb-3">
                    <p class="text-general f-14 pl-3">Nombre del equipo</p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:45" name="nombre">
                        <el-input v-model="model.nombre" placeholder="Ingresa el nombre" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-10">
                    <p class="text-general f-14 pl-3">Descripción del equipo</p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:500" name="descripción">
                        <el-input v-model="model.descripcion" placeholder="Describe el equipo" type="textarea" :rows="5" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-10">
                    <div class="row mx-0 mt-3 mb-2 text-general align-items-center">
                        <div class="col-auto px-2">
                            <el-checkbox v-model="compartirLider" :true-label="1" :false-label="0" />
                        </div>
                        <div class="col px-2 f-13">
                            {{ $config.vendedor }} compartirán el inventario del lider
                        </div>
                    </div>
                </div>
                <div class="col-10 my-3">
                    <p class="text-general f-14 pl-3">Añadir integrantes</p>
                    <el-select
                    v-model="id_leechero"
                    :placeholder="`Buscar ${$config.vendedor}`"
                    filterable
                    clearable
                    remote
                    class="w-100"
                    reserve-keyword
                    :remote-method="remoteMethod"
                    @change="agregarLeecheros"
                    >
                        <el-option
                        v-for="item in leecheros"
                        :key="item.id"
                        :label="`${item.nombre} - ${item.nombre_cedis}`"
                        :value="item.id"
                        :disabled="item.disabled"
                        />
                    </el-select>
                    <span v-if="validarLeechero" class="text-danger w-100 f-11"> Este campo es requerido </span>
                </div>
                <div class="col-10 my-3">
                    <div v-for="(leechero, l) in asignados" :key="l" class="row mx-0 border bg-light-f5 br-6 py-2 px-2 align-items-center mb-3">
                        <img :src="leechero.imagen" width="28" height="28" class="obj-cover rounded-circle" />
                        <div class="col text-center text-general f-15">
                            {{ leechero.nombre }}
                        </div>
                        <div class="col text-center text-general f-15">
                            {{ leechero.nombre_cedis }}
                        </div>
                        <div class="col-auto px-2">
                            <i class="icon-cancel text-general f-20 cr-pointer" @click="eliminarLeechero(l)" />
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Teams from "~/services/teams";

export default {
    data(){
        return {
            model: {
                nombre: null,
                descripcion: null,
            },
            leecheros: [],
            asignados: [],
            idsAsignados: [],
            idsEliminados: [],
            id_leechero: null,
            cargando: false,
            validarLeechero: false,
            compartirLider: 1,
        }
    },
    watch: {
        asignados(val){
            this.validarLeechero = !val.length
        },
    },
    methods: {
        toggle(){
            this.remoteMethod('')

            this.$refs.modalCrearEquipo.toggle();
        },
        async crearTeam(){
            try {

                const valid = await this.$refs.validacion.validate()
                if(!this.asignados.length) this.validarLeechero = true
                if(!valid || !this.asignados.length){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.model.leecheros = this.asignados
                this.model.compartir_lider = this.compartirLider
                this.cargando = true

                const {data} = await Teams.crearTeam(this.model);
                this.notificacion('Mensaje', 'Creado exitosamente', 'success');
                this.$emit('actualizar');
                this.$refs.modalCrearEquipo.toggle();
                this.limpiar()
            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
        checkSiEstaEliminado(el){
            let disabled = !!(el.disabled)
            if(disabled && this.idsEliminados.includes(el.id)) return false
            if(!disabled && this.idsAsignados.some(ele  => ele == el.id)) return true
            return disabled
        },
        async remoteMethod(query){
            try {
                let params = {
                    query,
                }
                const { data } = await Teams.listaLeecheros(params)
                this.leecheros = data.map(el => ({...el, disabled: this.checkSiEstaEliminado(el), principal: false}))

            } catch (e){
                this.options = [];
            }
        },
        agregarLeecheros(val){
            let leechero = this.leecheros.find(el => el.id == val)
            this.idsAsignados.push(val)
            leechero.principal = this.asignados.length ? false : true
            this.asignados.push(leechero)
            this.leecheros = []
            this.id_leechero = null
            this.remoteMethod('')
        },
        eliminarLeechero(l, id){
            const spliced = this.asignados.splice(l,1)
            this.idsAsignados.splice(l,1)
            this.idsEliminados.push(id)
            this.leecheros = []
            if (spliced[0].principal && this.asignados.length) this.asignados[0].principal = true
            this.remoteMethod('')
        },
        limpiar(){
            this.model = {
                nombre: null,
                descripcion: null,
            }
            this.idsAsignados = []
            this.asignados = []
            this.validarLeechero = false
            this.$refs.validacion.reset()
        }
    }
}
</script>

<style>

</style>
